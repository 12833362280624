	/*
  	Flaticon icon font: Flaticon
  	Creation date: 20/12/2019 11:36
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff2") format("woff2"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
    font-style: normal;
}

.flaticon-email:before { content: "\f100"; }
.flaticon-box:before { content: "\f101"; }
.flaticon-bolt:before { content: "\f102"; }
.flaticon-strategy:before { content: "\f103"; }
.flaticon-settings:before { content: "\f104"; }
.flaticon-export:before { content: "\f105"; }
.flaticon-map:before { content: "\f106"; }
.flaticon-invoice:before { content: "\f107"; }
.flaticon-team:before { content: "\f108"; }
.flaticon-office-store:before { content: "\f109"; }
.flaticon-briefing:before { content: "\f10a"; }
.flaticon-spaceship:before { content: "\f10b"; }
.flaticon-close:before { content: "\f10c"; }
