body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-card-body {
  padding: 0 15px !important;
  zoom: 1;
}

/*.ant-card.ant-card-bordered {
  display: inline-block;
  vertical-align: middle;
  transform: translateZ(0);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.3s;
  transition-property: transform;
}*/

.ant-card.ant-card-bordered:hover,
.ant-card.ant-card-bordered:focus,
.ant-card.ant-card-bordered:active {
  transform:translate(0, 10px) scale(1.1);
}

.ant-list-item {
  padding: 16px 0 !important;
}

.dETJPE .hosting_navbar .main_menu li {
  padding-right: 5px !important;
}

.flash-button{
  animation-name: flash;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

-webkit-animation-name: flash;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;

-moz-animation-name: flash;
  -moz-animation-duration: 1s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;
}

/*
@keyframes flash {
  0% { opacity: 1.0; }
  50% { opacity: 0.5; }
  100% { opacity: 1.0; }
}


@-webkit-keyframes flash {
  0% { opacity: 1.0; }
  50% { opacity: 0.5; }
  100% { opacity: 1.0; }
}

@-moz-keyframes flash {
  0% { opacity: 1.0; }
  50% { opacity: 0.5; }
  100% { opacity: 1.0; }
}*/

.register-button {
  border-radius: 4px;
  padding: 4px;
  -webkit-animation: circus-bg 8s cubic-bezier(.32,.01,.69,.98) infinite;
  animation: circus-bg 8s cubic-bezier(.32,.01,.69,.98) infinite;
  animation-direction: alternate;
  animation-play-state: running;
  background-image: url('./assets/image/saas/circus2.svg');
  background-repeat: no-repeat;
  background-size: 600%;
  background-position: bottom 8% left 0;
}

.flash-button-free {
  border-radius: 4px;
  padding: 4px;
  margin: 11px;
  -webkit-animation: circus-bg 8s cubic-bezier(.32,.01,.69,.98) infinite;
  animation: circus-bg 8s cubic-bezier(.32,.01,.69,.98) infinite;
  animation-direction: alternate;
  animation-play-state: running;
  background-image: url('./assets/image/saas/circus2.svg') !important;
  background-repeat: no-repeat;
  background-size: 600%;
  background-position: bottom 8% left 0;
}

.register-button .button-workflow{
  background-color: white;
  color: black;
  background-image: unset;
  text-transform: none;
}

.register-button .button-workflow:hover{
  color: white;
}

.register-button .flash-button{
  background-color: white !important;
  color: black !important;
  background-image: unset !important;
}

.button-more-info:hover {
  border-color: #5268db !important;
}

.button-more-info:focus {
  border-color: #5268db !important;
}



@keyframes circus-bg {
  0% {
    background-position: top 56% right 56%;
  }
  100% {
    background-position: bottom 2% left 2%;
  }
}


@-webkit-keyframes circus-bg {
  0% {
    background-position: top 56% right 56%;
  }
  100% {
    background-position: bottom 2% left 2%;
  }
}

@-moz-keyframes circus-bg {
  0% {
    background-position: top 56% right 56%;
  }
  100% {
    background-position: bottom 2% left 2%;
  }
}

.ant-input {
  height: 38px !important;
}

.ant-select-selection--single {
  height: 38px !important;
}

.ant-input:hover {
  border-color: #5268db !important;
}

.ant-input:focus{
  border-color: #5268db !important;
}

textarea.ant-input {
  min-height: 100px !important;
}


.ant-select-selection:hover {
  border-color: #5268db !important;
}

.ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
  border-color: #5268db !important;
}

.ant-select-selection__rendered {
  line-height: 35px !important;
}

.form-talk {
  display: flex;
  justify-content: center;
  align-self: center;
  align-content: center;
  flex-direction: column;
  padding: 50px;
  box-shadow: 0px 8px 20px 0px rgba(16, 66, 97, 0.07);
  text-transform: none !important;
}

.form-talk h2 {
  text-transform: none !important;
}

.form-talk label {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #496274;
}

.talk-text-div {
  display: flex;
  justify-content: space-between;
  align-self: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.talk-text-div .text-zone {
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;
  flex-direction: column;
  width: 70%;
  height: 90%;
  margin-top: 15%;
}

.main-div-talk {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 60px;
}
@media (max-width: 700px) {
  .main-div-talk {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 30px;
  }

  .form-talk {
    display: flex;
    justify-content: center;
    align-self: center;
    align-content: center;
    flex-direction: column;
    padding: 50px;
    margin-top: 20px;
    box-shadow: 0px 8px 20px 0px rgba(16, 66, 97, 0.07);
  }

  .talk-text-div .text-zone {
    display: flex;
    justify-content: flex-start;
    align-self: flex-start;
    flex-direction: column;
    width: 70%;
    height: 90%;
    margin-top: 30px;
    margin-bottom: 10%;
  }
}

.button-workflow {
  font-size: 18px;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.image-video:hover {
  cursor: pointer;
}

.player-wrapper-parent {
  padding-top: 210px;
}
@media (max-width: 990px) {
  .player-wrapper-parent {
    padding-top: 180px;
  }
}
@media (max-width: 767px) {
  .player-wrapper-parent {
    padding-top: 130px;
  }
}

.targetSectionDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 200px;
  padding: 35px;
  align-items: center;
  border: 1px solid #c1c1c1;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
  margin-bottom: 10px;
  transition: 4s;
  cursor: pointer;
}

.saasServiceBlock{
  cursor: pointer;
  border-radius: 5px;
  transition: transform .2s;
}

.saasServiceBlock:hover,
.saasServiceBlock:focus,
.saasServiceBlock:active {
  background-color:#c2c6fb2e;
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1);
}

.carousel {
  padding-bottom: 150px;
}

@media (max-height: 800px) {
  .carousel {
    padding-bottom: 50px;
  }
}

.scrollspy__menu.main_menu.menuLeft {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

