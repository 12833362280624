.ant-menu {
    height: 100%;
}

.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover{
    color: #565bb8 !important;
}

.ant-menu-item-selected{
    color: #565bb8 !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color:#eeeef7 !important;
}

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after{
    border-right: 3px solid #565bb8 !important;
}

.ant-menu-horizontal > .ant-menu-submenu-selected {
    border-bottom: 2px solid #565bb8;
}

.ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected{
    color: #565bb8 !important;
    border-bottom: 2px solid #565bb8 !important;
}

.ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected{
    color: #565bb8;
    border-bottom: 2px solid #565bb8;
}

.ant-modal{
    width: 80% !important;
    top: 10% !important;
}

.li {
    list-style-type: circle !important;
}

.ol .li{
    list-style-type: lower-alpha !important;
}

.ol > .ol .li {
    list-style-type: lower-alpha !important;
}

.freeSignUp {
    text-transform: none !important;
}
